<template>
  <div>
    <v-toolbar color="white" flat>
      <v-toolbar-title>Employees</v-toolbar-title>
      <v-divider
          class="mx-2"
          inset
          vertical
      ></v-divider>
      <v-spacer></v-spacer>
      <v-switch class="px-8 pt-7" color="primary" label="Show inactive employees"
                v-model="showInactive">
      </v-switch>
      <v-btn @click="newItem()" class="px-5" color="primary" dark>New Employee</v-btn>

    </v-toolbar>

    <v-data-table
        :headers="headers"
        :items="items"
        dense
        disable-pagination
        hide-default-footer
    >

      <template v-slot:body="{ items }">
        <tbody>
        <tr :key="item.id" v-for="item in items">
          <td :class="{'font-weight-black green--text': item.on_clock}"
              class="text-left">
            {{ item.last }}, {{ item.first }}
          </td>
          <td class="justify-center layout">
            <v-icon class="mr-2" small v-if="item.active">
              mdi-check-bold
            </v-icon>
          </td>
          <td class="text-center">{{ financialI(item.pay_rate) }}</td>
          <td :class="{'font-weight-black green--text': item.on_clock}"
              class="text-center">
            {{ prettyDT(item.last_clockin) }}
          </td>
          <td class="justify-center layout">
            <v-icon @click="editItem(item)" class="mr-2" small>
              mdi-pencil
            </v-icon>
          </td>
        </tr>
        <tr>
          <td class="font-weight-bold">Total Employees: {{ items.length }}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </template>

    </v-data-table>

    <edit-employee></edit-employee>

  </div>
</template>

<script>
import EditEmployee from './Edit'
import {eventBus} from '../../../main'
import {EVT_EDIT_EMPLOYEE} from '../../constants'
import {financialI, prettyDT} from '../utils'

export default {
  name: 'Employees',
  components: {EditEmployee},

  created() {
    console.log('Created:', this.$options.name)
  },
  mounted() {
    console.log('Mounted:', this.$options.name)
  },
  beforeDestroy() {
    console.log('Destroy:', this.$options.name)
    this.errorMessage = ''
  },

  computed: {
    items() {
      var items = []
      if (this.showInactive) {
        items = this.$store.getters.employees // all incl. inactive
      } else {
        items = this.$store.getters.activeEmployees // only active
      }
      return items
    }
  },

  methods: {
    financialI,
    prettyDT,

    // isOnClock (empl) {
    //   return (empl.on_clock ? empl.last_clockin : '')
    // },

    newItem() {
      console.log('New employee')
      var rec = {
        index: -1,
        item: {
          id: -1,
          last: '', // empty empl record
          first: '',
          pay_rate: '',
          active: true
        }
      }
      eventBus.$emit(EVT_EDIT_EMPLOYEE, rec)
    },
    editItem(item) {
      console.log('Edit employee:', item, item.id)
      var rec = {
        index: this.items.indexOf(item),
        item: {
          id: item.id,
          last: item.last,
          first: item.first,
          pay_rate: financialI(item.pay_rate),
          active: item.active
        }
      }
      eventBus.$emit(EVT_EDIT_EMPLOYEE, rec)
    }
  },

  data() {
    return {
      showInactive: false,
      headers: [
        {text: 'Name', align: 'left', value: 'last'},
        {text: 'Active', align: 'center', value: 'active'},
        {text: 'Pay Rate', align: 'center', value: 'pay_rate'},
        {text: 'Last seen', align: 'center', value: 'last_clockin'},
        {text: 'Edit', align: 'center', value: 'edit', sortable: false}
      ]
    }
  }
}
</script>

<style scoped>

</style>
