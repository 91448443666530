<template>
  <v-dialog max-width="500px" v-model="editDialog">

    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container grid-list-md>
          <v-row>
            <v-text-field label="Last name" v-model="editItem.last"></v-text-field>
          </v-row>
          <v-row>
            <v-text-field label="First name" v-model="editItem.first"></v-text-field>
          </v-row>
          <v-row>
            <v-text-field label="Pay rate" v-model="editItem.pay_rate"></v-text-field>
          </v-row>
          <v-row>
            <v-switch
                    color="primary"
                    label="Active"
                    v-model="editItem.active"
            ></v-switch>
            <span v-if="editItem.active">
                   An active employee will appear on the tablet
                    and may clock in or out.
                     </span>
            <span v-else>
                    An inactive employee will not appear on the tablet and cannot clock
                    in or out. If they have hours worked in recent pay periods, those hours will
                    appear on the payroll report.
                    </span>
          </v-row>
        </v-container>

        <v-alert :value="(errorMessage!='')" outlined type="error">
          {{ errorMessage }}
        </v-alert>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="editDone" color="blue darken-1" text>Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-btn @click="save" color="blue darken-1" text>Save</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>

    <busy :message="busyMessage"></busy>

  </v-dialog>
</template>

<script>
  import Busy from '../Busy'
  import axios from 'axios'
  import { EVT_EDIT_EMPLOYEE } from '../../constants'
  import { eventBus } from '../../../main'
  import { strToInt } from '../utils'

  export default {
    name: "EditEmployee",
    components: { Busy },

    created () {
      console.log('Created:', this.$options.name)
      eventBus.$on(EVT_EDIT_EMPLOYEE, (rec) => {
        this.editIndex = rec.index
        this.editItem = rec.item
        this.formTitle = this.editIndex === -1 ? 'New employee' : 'Edit employee'
        this.editDialog = true
      })
    },
    beforeDestroy () {
      console.log('Destroy:', this.$options.name)
      this.busyMessage = ''
      this.errorMessage = ''
    },

    methods: {
      showAlert (msg) {
        this.errorMessage = msg
        setTimeout(() => (this.errorMessage = ''), 5000)
      },

      editDone () {
        this.busyMessage = ''
        this.errorMessage = ''
        this.editDialog = false
      },
      save () {
        console.log('SAVE', this.editItem)
        this.busyMessage = 'Saving...'
        // convert pay_rate to int
        this.editItem.pay_rate = strToInt(this.editItem.pay_rate)
        if (this.editIndex > -1) {
          axios.patch('/employee/edit', this.editItem)
              .then(res => {
                console.log('PUT', res)
                this.busyMessage = ''
                this.editDone()
              })
              .catch(error => {
                console.log(error.toString())
                this.busyMessage = ''
                this.showAlert('Error unable to save edited employee record. ' +
                    error.toString())
              })
        } else {
          axios.post('/employee/add', this.editItem)
              .then(res => {
                console.log('POST', res)
                this.busyMessage = ''
                this.editDone()
              })
              .catch(error => {
                console.log(error.toString())
                this.busyMessage = ''
                this.showAlert('Error unable to save new employee record. ' +
                    error.toString())
              })
        }
      }

    },

    data () {
      return {
        editDialog: false,
        busyMessage: '',
        errorMessage: '',
        editIndex: -1,
        editItem: {
          id: -1,
          last: '',
          first: '',
          active: true
        },
        formTitle: ''
      }
    }

  }
</script>

<style scoped>

</style>
